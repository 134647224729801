import APIService from '@/services/api-service'
const primary = '/dispatch'

class FleetService extends APIService {
  public constructor () {
    super()
    this.mountAuthorizationToken()
  }

  public getAllBowsers () {
    return this.connector.get(`${primary}/bowsers`)
  }

  public getAllTrucks () {
    return this.connector.get(`${primary}/trucks`)
  }

  public getBargeNames () {
    return this.connector.get(`${primary}/barges`)
  }
}

export default new FleetService()
