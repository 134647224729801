
import {
  Component,
  Vue
} from 'vue-property-decorator'
import LocationService from '../../services/location-service'
import FleetService from '../../services/fleet-service'
import BaseService from '../../services/base-service'
import ActivityService from '../../services/activity-service'
import ItemService from '../../services/item-service'
import ScheduleService from '../../services/schedule-service'
import {
  ToastModule
} from '@/store/modules/ToastModule'
import {
  mask
} from 'vue-the-mask'
import {
  AuthModule
} from '@/store/modules/AuthModule'
import {
  BreadcrumbModule
} from '@/store/modules/BreadcrumbModule'
import DateHelper from '@/mixins/date-mixins'
import Status from '@/components/Status/index.vue'
import {
  NotifyModule
} from '@/store/modules/NotifyModule'
  @Component({
    name: 'TruckDispatch',
    components: {
      Status
    },
    directives: {
      mask
    }
  })
export default class TruckDispatch extends Vue {
    protected isDispatchCreated = false
    protected isTableBusy = false
    protected contentType: any = {}
    protected barges: any = []
    protected location: any = {}
    protected trucks: any = []
    protected ports: any = []
    protected notes: any = []
    protected jobNumbers: any = []
    protected dispatch: any = {
      id: null,
      dispatch_date: null,
      dispatch_quantity: null,
      port: {}
    }

    protected fields: any = [{
      key: 'collapse',
      label: '',
      thStyle: {
        width: '2%'
      },
      class: 'text-center'
    },
    {
      key: 'vehicle_number',
      label: 'Vehicle No.',
      thStyle: {
        width: '10%'
      }
    },
    {
      key: 'vessel',
      label: 'Vessel',
      thStyle: {
        width: '10%'
      }
    },
    {
      key: 'status',
      label: 'Status',
      thStyle: {
        width: '10%'
      }
    },
    {
      key: 'driver',
      label: 'Driver',
      thStyle: {
        width: '10%'
      }
    },
    {
      key: 'vehicle_capacity',
      label: 'Capacity',
      thStyle: {
        width: '10%'
      }
    },
    {
      key: 'dispatch_note_number',
      label: 'Note No.',
      thStyle: {
        width: '10%'
      }
    },
    {
      key: 'trip_number',
      label: 'Trip No.',
      thStyle: {
        width: '10%'
      }
    },
    {
      key: 'action',
      label: '',
      thStyle: {
        width: '10%'
      },
      class: 'text-center'
    }
    ]

    created () {
      this.boot()
    }

    mounted () {
      this.notes = this.notes.map(notes => ({
        ...notes,
        isEdit: true
      }))
    }

    protected boot () {
      BreadcrumbModule.setBreadcrumb(this.$route)
      this.populateTrucks()
      this.populateDispatch()
      this.populatePorts()
      this.populateBarge()
      this.populateJobNumbers()
      this.populatePlants()
      this.populateContentTypes()
    }

    protected populatePorts () {
      LocationService.getPorts().then((response) => {
        response.data.forEach((port) => {
          this.ports.push({
            value: {
              id: port.id,
              name: port.name
            },
            text: port.name
          })
        })
      })
    }

    protected populateContentTypes () {
      ItemService.getWasteContentTypes().then((response) => {
        response.data.forEach((item) => {
          if (item.display_name === 'Garbage') {
            this.contentType = { id: item.id, name: item.display_name }
          }
        })
      })
    }

    public populateDispatch () {
      this.isTableBusy = true
      BaseService.getDispatch('Garbage').then((response) => {
        this.isTableBusy = false
        if (response.data === 'empty') {
          this.isDispatchCreated = false
          this.dispatch.dispatch_date = DateHelper.today('dd/mm/yyyy')
          this.dispatch.port = {}
          this.dispatch.dispatch_number = null
          this.dispatch.dispatch_quantity = null
          this.notes = []
          this.addLine()
          return false
        }

        this.isDispatchCreated = true
        this.dispatch = {
          id: response.data.id,
          dispatch_date: response.data.dispatch_date,
          dispatch_number: `00${response.data.id}`,
          dispatch_quantity: response.data.dispatch_quantity,
          port: {
            id: response.data.from_location_id,
            name: response.data.from_location
          }
        }

        this.notes = response.data.notes.map(note => ({
          isEdit: false,
          id: note.id,
          dispatch_id: note.dispatch_id,
          isExceed: false,
          validity: {
            valid: false,
            commence_loading: false,
            completed_loading: false
          },
          truck: {},
          vehicle_id: note.vehicle_id,
          vehicle_number: note.vehicle_number,
          vehicle_capacity: note.vehicle_capacity,
          vehicle_wheels: note.vehicle_wheels,
          dispatch_note_number: note.dispatch_note_number,
          status: note.status,
          trip_number: note.trip_number,
          driver: note.driver,
          completed_loading: note.completed_loading,
          commence_loading: note.commence_loading,
          total_tonnage: note.total_tonnage === null ? 0 : note.total_tonnage,
          lines: note.lines.length === 0 ? [{
            isEdit: true,
            id: null,
            barge_id: null,
            barge_name: null,
            job_id: null,
            job_number: null,
            tonnage: null
          }] : note.lines.map(line => ({
            ...line
          })),
          is_vessel_dispatch: note.is_vessel_dispatch === 1,
          is_send_to_mepa: note.is_send_to_mepa === 1,
          is_mepa_verified: note.is_mepa_verified === 1,
          is_send_to_customs: note.is_send_to_customs === 1,
          is_custom_verified: note.is_custom_verified === 1,
          is_gate_out: note.is_gate_out === 1,
          location: note.location_name === null ? {} : {
            id: note.location_id,
            name: note.location_name
          },
          modified_user: note.modified_user
        }))
      })
    }

    protected save () {
      const header: any = {
        id: this.dispatch.id,
        dispatch_date: this.dispatch.dispatch_date,
        dispatch_type: 'Garbage',
        created_user: AuthModule.user.fullname,
        modified_user: AuthModule.user.fullname
      }

      if (Object.keys(this.dispatch.port).length === 0) {
        header.from_location_id = 4
        header.from_location = 'Colombo'
      } else {
        header.from_location_id = this.dispatch.port.id
        header.from_location = this.dispatch.port.name
      }

      const notes: any = []
      this.notes.forEach((note, index) => {
        note.isEdit = false
        notes.push({
          id: note.id,
          dispatch_id: this.dispatch.id,
          is_vessel_dispatch: note.is_vessel_dispatch,
          dispatch_note_number: index + 1,
          vehicle_id: note.vehicle_id,
          vehicle_type: 'Truck',
          vehicle_number: note.vehicle_number,
          vehicle_wheels: note.vehicle_wheels,
          vehicle_capacity: note.vehicle_capacity,
          driver: note.driver,
          trip_number: note.trip_number,
          created_user: AuthModule.user.fullname,
          modified_user: AuthModule.user.fullname
        })
      })

      if (this.isDispatchCreated) this.update(header, notes)
      else this.create(header, notes)
    }

    protected create (header, notes) {
      BaseService.create(header, notes).then((response) => {
        ToastModule.message(response.data.message)
        this.isDispatchCreated = true
        this.populateDispatch()
      }).catch(error => {
        ToastModule.message(error.response.data.message)
      })
    }

    protected update (header, notes) {
      BaseService.update(header, notes).then((response) => {
        ToastModule.message(response.data.message)
        this.isDispatchCreated = false
        this.populateDispatch()
      }).catch(error => {
        ToastModule.message(error.response.data.message)
      })
    }

    protected validateTime (index: number, key: any) {
      const isValid = /^([0-1]?[0-9]|2[0-4]):([0-5][0-9])(:[0-5][0-9])?$/.test(this.notes[index][key])
      if (isValid) {
        this.notes[index].validity[key] = false
        this.notes[index].validity.valid = true
        return true
      }
      this.notes[index].validity[key] = true
      this.notes[index].validity.valid = false
      return false
    }

    protected saveTimeAndTonnageDetails (index: any, item: any) {
      if (item.commence_loading === null || item.completed_loading === null) {
        return NotifyModule.set('Time fields might be empty, Please check...')
      }

      if (!this.validateTime(index, 'commence_loading') || !this.validateTime(index, 'completed_loading')) { return NotifyModule.set('Incorrect time formats, Please check...') }

      if (item.isExceed) return NotifyModule.set('Tonnage exceeds vehicle capacity, Please check...')

      const note: any = {
        id: item.id,
        dispatch_id: item.dispatch_id,
        commence_loading: item.commence_loading,
        completed_loading: item.completed_loading,
        item_id: this.contentType.id,
        content_type: this.contentType.name,
        dispatch_type: 'Garbage',
        status: 'loaded',
        is_vessel_dispatch: item.is_vessel_dispatch,
        total_tonnage: item.total_tonnage,
        dispatch_quantity: this.dispatch.dispatch_quantity,
        modified_user: item.modified_user
      }
      const jobs: any = []
      item.lines.forEach((line) => {
        line.isEdit = false
        jobs.push({
          dispatch_note_id: item.id,
          line_id: line.id === null ? 0 : line.id,
          barge_id: line.barge_id,
          barge_name: line.barge_name,
          job_id: line.job_id,
          job_number: line.job_number,
          tonnage: line.tonnage,
          is_vessel_dispatch: item.is_vessel_dispatch,
          created_user: AuthModule.user.fullname,
          modified_user: AuthModule.user.fullname
        })
      })
      const data = {
        note: note,
        jobs: jobs
      }
      ActivityService.save(data).then((response) => {
        ToastModule.message(response.data.message)
      }).catch(error => {
        ToastModule.message(error.response.data.message)
      })
    }

    protected saveApprovals (item: any) {
      if (item.total_tonnage === 0) return NotifyModule.set('Please save tonnage details first..')
      const dispatch = {
        id: item.id,
        dispatch_id: item.dispatch_id,
        is_send_to_mepa: item.is_send_to_mepa,
        is_mepa_verified: item.is_mepa_verified,
        is_send_to_customs: item.is_send_to_customs,
        is_custom_verified: item.is_custom_verified,
        is_gate_out: item.is_gate_out,
        location_id: this.location.id,
        location_name: this.location.name,
        status: 'dispatched',
        modified_user: item.modified_user
      }
      ActivityService.update(dispatch).then((response) => {
        ToastModule.message(response.data.message)
      }).catch(error => {
        ToastModule.message(error.response.data.message)
      })
    }

    public async setTruck (tableIndex: number, truck: any) {
      this.notes[tableIndex].vehicle_id = truck.id
      this.notes[tableIndex].vehicle_number = truck.vehicle_number
      this.notes[tableIndex].status = 'open'
      this.notes[tableIndex].vehicle_capacity = truck.capacity
      this.notes[tableIndex].vehicle_wheels = truck.wheels
      this.notes[tableIndex].driver = truck.driver
      const tripCount = this.notes.filter(note => note.vehicle_number === truck.vehicle_number).length
      if (tripCount > 1) this.notes[tableIndex].trip_number = tripCount
      else this.notes[tableIndex].trip_number = 1
    }

    protected populateBarge () {
      FleetService.getBargeNames().then((response) => {
        response.data.forEach((barge) => {
          this.barges.push({
            value: barge.barge_id,
            text: barge.barge_name
          })
        })
      })
    }

    protected populatePlants () {
      LocationService.getPlants().then((response) => {
        response.data.forEach(item => {
          this.location = { id: item.id, name: item.name }
        })
      })
    }

    protected async populateTrucks () {
      const response = await FleetService.getAllTrucks()
      response.data.forEach((element: {
        id: any;
        vehicleNo: any,
        wheels: any,
        capacity: any,
        driver: any
      }) => {
        this.trucks.push({
          value: {
            id: element.id,
            vehicle_number: element.vehicleNo,
            wheels: element.wheels,
            capacity: element.capacity,
            driver: element.driver
          },
          text: element.vehicleNo
        })
      })
    }

    private async populateJobNumbers () {
      const response = await ScheduleService.getScheduledJobs()
      response.data.forEach((element) => {
        this.jobNumbers.push({
          value: element.job_id,
          text: element.job_number
        })
      })
    }

    protected deleteDispatchNote (tableIndex: number, item: any) {
      if (item.id === null) {
        this.notes.splice(tableIndex, 1)
      } else {
        if (confirm(`Are you sure you want to delete this Vehicle ${item.vehicle_number}?`) === true) {
          BaseService.delete(item.id).then((response) => {
            ToastModule.message(response.data.message)
            this.notes.splice(tableIndex, 1)
          }).catch(error => {
            ToastModule.message(error.response.data.message)
          })
        }
      }
    }

    protected deleteTonnageDetails (tableIndex: any, item: any, index: number) {
      if (item.lines[index].id === null) {
        item.lines.splice(index, 1)
        this.calculateTotalTonnage(tableIndex)
      } else {
        if (confirm(`Are you sure you want to delete this Job ${item.lines[index].job_number}?`) === true) {
          ActivityService.delete(item.dispatch_id, item.id, item.lines[index].job_id).then((response) => {
            item.lines.splice(index, 1)
            this.calculateTotalTonnage(tableIndex)
            ToastModule.message(response.data.message)
          }).catch(error => {
            ToastModule.message(error.response.data.message)
          })
        }
      }
    }

    protected setBargeName (tableIndex, index, bargeId) {
      const barge = this.barges.find(barge => barge.value === bargeId)
      this.notes[tableIndex].lines[index].barge_name = barge.text
    }

    protected setJobNumber (tableIndex, index, jobId) {
      const job = this.jobNumbers.find(job => job.value === jobId)
      this.notes[tableIndex].lines[index].job_number = job.text
    }

    protected calculateTotalTonnage (tableIndex: number) {
      this.notes[tableIndex].total_tonnage = this.notes[tableIndex].lines.reduce((total, line) =>
        total + parseFloat(line.tonnage), 0)

      this.dispatch.dispatch_quantity = this.notes.reduce((total, note) => total + parseFloat(note.total_tonnage), 0)

      const capacity = (parseInt(this.notes[tableIndex].vehicle_capacity) / 1000)
      if (this.notes[tableIndex].total_tonnage > capacity) {
        this.notes[tableIndex].isExceed = true
      } else {
        this.notes[tableIndex].isExceed = false
      }
    }

    protected addLine () {
      this.notes.push({
        isEdit: true,
        id: null,
        validity: {
          valid: false,
          commence_loading: false,
          completed_loading: false
        },
        isExceed: false,
        truck: {},
        vehicle_id: null,
        vehicle_number: null,
        vehicle_capacity: null,
        vehicle_wheels: null,
        dispatch_note_number: null,
        status: null,
        trip_number: null,
        driver: null,
        completed_loading: null,
        commence_loading: null,
        content_type: {},
        total_tonnage: 0,
        is_vessel_dispatch: true,
        is_send_to_mepa: false,
        is_mepa_verified: false,
        is_send_to_customs: false,
        is_custom_verified: false,
        is_gate_out: false,
        location: {},
        lines: [],
        created_user: AuthModule.user.fullname,
        modified_user: AuthModule.user.fullname
      })
    }

    protected addJobLine (tableIndex) {
      this.notes[tableIndex].lines = this.notes[tableIndex].lines.map(line => ({
        ...line,
        isEdit: false
      }))
      this.notes[tableIndex].lines.push({
        isEdit: true,
        id: null,
        barge_id: null,
        barge_name: null,
        job_id: null,
        job_number: null,
        tonnage: null
      })
    }

    protected complete () {
      const completeCount = this.notes.filter(note => note.status !== 'dispatched').length
      if (completeCount !== 0) return NotifyModule.set('Dispatch is incomplete, Please check..')
      const dispatch: any = {
        id: this.dispatch.id,
        // dispatch_quantity: this.dispatch.dispatch_quantity,
        dispatch_quantity: this.notes.reduce((total, note) => total + parseFloat(note.total_tonnage), 0),
        modified_user: AuthModule.user.fullname
      }
      BaseService.complete(dispatch).then((response) => {
        this.populateDispatch()
        ToastModule.message(response.data.message)
      }).catch(error => {
        ToastModule.message(error.response.data.message)
      })
    }
}

